import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "icon" ]
    static values = {
        pendingTooltip: String,
        loadingTooltip: String,
        successTooltip: String,
        errorTooltip: String,
        defaultState: Boolean
    }

    connect() {
        if (this.defaultStateValue === true) {
            this.success()
        } else {
            this.pending()
        }
    }

    // Sets the pending icon and optionally a tooltip
    pending() {
        if (this.hasPendingTooltipValue) {
            this.iconTarget.setAttribute("data-tooltip", this.pendingTooltipValue)
        }

        this.iconTarget.innerHTML = "<i class='fas fa-circle is-pending'></i>"
    }

    // Sets the loading icon and optionally a tooltip
    loading() {
        if (this.hasLoadingTooltipValue) {
            this.iconTarget.setAttribute("data-tooltip", this.hasLoadingTooltipValue)
        }

        this.iconTarget.innerHTML = "<i class='fas fa-spinner fa-spin is-pending'></i>"
    }

    // Sets the success icon and optionally a tooltip
    success() {
        if (this.hasSuccessTooltipValue) {
            this.iconTarget.setAttribute("data-tooltip", this.successTooltipValue)
        }

        this.iconTarget.innerHTML = "<i class='fas fa-check-circle is-primary'></i>"
    }

    // Sets the error icon and optionally a tooltip
    error() {
        if (this.hasErrorTooltipValue) {
            this.iconTarget.setAttribute("data-tooltip", this.errorTooltipValue)
        }

        this.iconTarget.innerHTML = "<i class='fas fa-exclamation-circle is-error'></i>"
    }
}
