import { Controller } from "stimulus"
import debounce from "lodash/debounce"
import Rails from "@rails/ujs"

export default class extends Controller {
    static targets = [ "errorMessage", "form" ]

    initialize() {
        this.submit = debounce(this.submit, 500, { leading: false, trailing: true })
    }

    // Displays the error message
    errorMessage() {
        this.errorMessageTarget.style.visibility = "visible"
    }

    // Fires the form
    submit() {
        this.errorMessageTarget.style.visibility = "hidden"
        Rails.fire(this.formTarget, "submit")
    }
}
