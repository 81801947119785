import { Controller } from "stimulus"
import debounce from "lodash/debounce"

export default class extends Controller {
    static targets = [ "content" ]

    initialize() {
        this.checked = debounce(this.checked, 20, { leading: true, trailing: false})
    }

    // Toggles the background color of a checkbox
    checked(event) {
        event.currentTarget.classList.toggle("is-checked")
    }

    // Determines which location settings we need and displays first one to user
    async startLocationSettings() {
        await fetch("/wizard/enabled-locations").then(response => response.json()).then(data => this.enabledLocations = data)

        if (this.enabledLocations.length === 0) {
            window.dispatchEvent(new CustomEvent("show-review"))
        } else {
            this.loadNextLocation()
        }
    }

    // Displays the next location settings to the user
    loadNextLocation() {
        let nextLocation = this.enabledLocations.shift()
        let last = false

        if (this.enabledLocations.length === 0) {
            last = true
        }

        this.#fetchContent(`/wizard/edit-location?location=${nextLocation}&last=${last}`)
    }

    // Private method replacing the content's HTML with the body returned from the path
    #fetchContent(path) {
        fetch(path)
            .then(response => response.text())
            .then(html => this.contentTarget.innerHTML = html)
    }
}
